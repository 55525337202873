<template>
  <section>
    <div class="headerjob">
      <v-container class="container-custom">
        <v-row>
          <v-col> <h1 class="titlepage">Add Job</h1></v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="container-custom padding-inside">
      <v-row>
        <v-col md="12">
          <AddJob />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import AddJob from "@/components/AddJob";
export default {
  name: "CompanyAddJob",
  components: { AddJob },
  data: () => ({
    headercontent: {
      title: "Add Job",
      addlink: "/dashboard/training/add",
      downloadlink: true,
    },
  }),
};
</script>

<style lang="scss" scoped></style>
